import { motion } from "framer-motion"
import { number } from "prop-types"
import React, { useEffect, useState } from "react"
import { useInterval } from "react-use"
import scrollTo from "../animations/scrollTo"
import useScrollDirection from "../hooks/useScrollDirection"

const NavigationOrbs = () => {
  const scrollDir = useScrollDirection()
  const [numberOfSections, setNumberOfSections] = useState(0)
  const [sectionWrappers, setSectionWrappers] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const sectionWrappers = document.querySelectorAll(".section-wrapper")
    setSectionWrappers(sectionWrappers)
    setNumberOfSections(sectionWrappers.length)
  }, [])

  const determainActiveSection = () => {
    const sectionsYVal = [...sectionWrappers].map(
      section => section.getBoundingClientRect().y
    )
    setActiveIndex(returnSmallestNegativeIndex(sectionsYVal))
  }

  useInterval(determainActiveSection, 500)

  return (
    <motion.div
      className="navigation-orbs"
      initial={{ y: "-50%" }}
      animate={{ x: scrollDir === "down" ? "-100%" : "50%" }}
      // transition={{ type: "spring", damping: 0 }}
    >
      {Array.from(Array(numberOfSections), (e, i) => (
        <div
          className={`orb ${activeIndex === i ? "active" : ""}`}
          key={i}
          onClick={() => {
            scrollTo(sectionWrappers[i])
          }}
        ></div>
      ))}
    </motion.div>
  )
}

export default NavigationOrbs

function indexOfMin(arr) {
  if (arr.length === 0) {
    return -1
  }

  var min = Math.abs(arr[0])
  var minIndex = 0

  for (var i = 1; i < arr.length; i++) {
    const isSmallerThanMin = Math.abs(arr[i]) < min
    // const isNegativeNum = Math.sign(arr[i]) === "-1"
    // if (isSmallerThanMin && isNegativeNum) {
    if (isSmallerThanMin) {
      minIndex = i
      min = arr[i]
    }
  }

  return minIndex
}

function returnSmallestNegativeIndex(array) {
  let smallestValue = Math.abs(array[0])
  let smallestValueIndex = 0

  array.forEach((number, index) => {
    if (Math.sign(number) === 1) number += 400
    if (Math.abs(number) > smallestValue) return

    smallestValue = Math.abs(number)
    smallestValueIndex = index
  })
  // console.log({ smallestValue, smallestValueIndex })
  return smallestValueIndex
}
