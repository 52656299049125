import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const DoubleOImage = ({ src, className = "", style }) => {
  const data = useStaticQuery(graphql`
    query {
      conference1: file(relativePath: { eq: "doubleO/do-conference-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      conference2: file(relativePath: { eq: "doubleO/do-conference-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "doubleO/do-logo-full.png" }) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iphone: file(relativePath: { eq: "doubleO/do-iphone.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ipad: file(relativePath: { eq: "doubleO/do-ipad.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      measure: file(relativePath: { eq: "doubleO/do-icon-measure2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      elevate: file(relativePath: { eq: "doubleO/do-icon-elevate2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      focus: file(relativePath: { eq: "doubleO/do-icon-focus2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      realign: file(relativePath: { eq: "doubleO/do-icon-realign2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      books1: file(relativePath: { eq: "doubleO/do-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      books2: file(relativePath: { eq: "doubleO/do-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      books3: file(relativePath: { eq: "doubleO/do-books-3.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dob1: file(relativePath: { eq: "doubleO/dob1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dob2: file(relativePath: { eq: "doubleO/dob2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dob3: file(relativePath: { eq: "doubleO/dob3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dob4: file(relativePath: { eq: "doubleO/dob4.png" }) {
        childImageSharp {
          fluid(quality: 99) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      books4: file(relativePath: { eq: "doubleO/do-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pattern: file(relativePath: { eq: "doubleO/do-shirt-pattern.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      shirt: file(relativePath: { eq: "doubleO/do-6.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      box: file(relativePath: { eq: "doubleO/do-7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      box2: file(relativePath: { eq: "doubleO/do-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      card: file(relativePath: { eq: "doubleO/do-10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      businesssuit: file(relativePath: { eq: "doubleO/do-9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dangerous: file(relativePath: { eq: "doubleO/dangerous.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bg: file(relativePath: { eq: "doubleO/do-logo-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  if (!data[src]) {
    console.error("image not found:", src)
  }
  return (
    <GatsbyImage
      fluid={data[src].childImageSharp.fluid}
      className={`img--${src} ${className}`}
      style={style}
    />
  )
}
export default DoubleOImage
