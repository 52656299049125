import React, { useContext } from "react"
import { Link } from "gatsby"

import { context } from "../../provider"

const TransitionLink = ({ to, color, children, preventDefault }) => {
  const { transitionPage } = useContext(context)

  return (
    <Link
      to={to}
      onClick={e => {
        e.preventDefault()

        if (preventDefault) return
        transitionPage({
          to: to,
          color: color,
        })
      }}
    >
      {children}
    </Link>
  )
}

export default TransitionLink
