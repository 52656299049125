import React from "react"
// import Seo from "../../components/seo"

import Img from "../../assets/images/double-o-images"
import ProjectAnimations from "../../components/projects/projectAnimations"

import IphoneVideo from "../../assets/images/doubleO/domobile.mp4"
import IpadVideo from "../../assets/images/doubleO/do-ipad-video.mp4"

import Slide from "../../components/projects/Slide"
import Spacer from "../../components/projects/Spacer"

import "./template-styles.scss"
import "./double-o.scss"

import SEO from "../../components/seo"
import RightArrowSVG from "../../assets/svg/right-arrow.svg"
import TransitionLink from "../../components/TransitionLink"
import NavigationOrbs from "../../components/NavigationOrbs"

const doubleoBlue = "#212931"
const doubleoOrange = "#DD574C"

const DoubleOProject = () => {
  ProjectAnimations()

  return (
    <div className="project double-o">
      <NavigationOrbs />

      <SEO
        title="Double-O"
        description="Brand strategy and implementation for a business consultancy that’s anything but corporate. Strategy, name, brand identity, tone of voice, key messaging and content, photography, website, design collateral."
      />

      {/* half-full-image half-text */}
      <Slide className="intro">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="books4" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: doubleoOrange, color: doubleoBlue }}
        >
          <div>
            <h1>Double-O</h1>
            <p>
              You could do business. Or you could do dangerously good business.
              Double-O Consultants specialise in Lean and Agile methods and
              processes that help you get the job done. They’re sharp, smart,
              irreverent and highly effective disruptors. So that’s how we
              positioned them. <br />
              <br />
              Strategy, name, brand identity, tone of voice, key messaging and
              content, photography, website, design collateral.
            </p>
          </div>
        </div>
      </Slide>

      {/* half-centered-image half-text */}

      <Slide className="section-1">
        <div className="panel--w-half panel--h-screen panel--image panel--image--centered">
          <Img src="logo" className="logo" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: doubleoOrange, color: doubleoBlue }}
        >
          <div>
            <p>
              The concept of the storytelling brand platform informed the name,
              tagline, the bullet-hole DO logomark, and a colour combination
              that paired midnight blue with a shock of orange that nobody saw
              coming. Double-O people are fearless. That subtle naming reference
              was no accident.
            </p>
          </div>
        </div>
      </Slide>

      {/* Spacer and SVG */}

      {/* <Slide className="section-2">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text panel--image panel--image--svg panel--image--svg--large"
          style={{ backgroundColor: doubleoOrange }}
        >
          <DangerousSvg />
        </div>
      </Slide> */}
      <Slide className="section-2">
        <Spacer />

        <div
          className="panel--w-half panel--h-screen panel--text panel--image--centered"
          style={{ backgroundColor: doubleoOrange }}
        >
          <Img src="dangerous" className="logo" />
        </div>
      </Slide>

      {/* screen-container and text */}

      <Slide className="section-3">
        <div
          className="iphone screen-container panel--w-half panel--h-screen "
          style={{ backgroundColor: doubleoBlue }}
        >
          <div className=" screen-container__inner-container">
            <Img src="iphone" className="screen-container__frame" />
            <div className="screen-container__screen">
              <video src={IphoneVideo} autoPlay playsInline muted loop></video>
            </div>
          </div>
        </div>
        <div
          className="panel--w-half panel--h-screen centered-content"
          style={{ backgroundColor: doubleoOrange, color: doubleoBlue }}
        >
          <div className="panel--text">
            <p>
              Not a stock image of a suit with a laptop in sight. The design of
              the bespoke, interactive website is built on typography and
              graphics, not clichés.
              <br />
              <br />
              Experience the full site{" "}
              <a
                href="https://doubleo.nz/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold underline"
              >
                here
              </a>
            </p>
          </div>
        </div>
      </Slide>

      {/* full-screen-container */}

      <Slide className="section-4">
        <div
          className="ipad panel--w-full panel--h-screen screen-container"
          style={{ backgroundColor: doubleoBlue }}
        >
          <div className="screen-container__inner-container">
            <Img src="ipad" className="screen-container__frame" />
            <div className="screen-container__screen">
              <video src={IpadVideo} autoPlay playsInline muted loop></video>
            </div>
          </div>
        </div>
      </Slide>

      {/* carousel-contaier and text */}
      <Slide className="section-5z">
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: doubleoOrange, color: doubleoBlue }}
        >
          <div>
            <p>
              Covert, not overt. The (not so) corporate profile features a trail
              of bullet-hole die-cut icons that tell the story.
            </p>
          </div>
        </div>

        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="bg" />
        </div>
      </Slide>

      <div className="section-5 carousel-container section-wrapper">
        <Spacer />

        <div className="items-container-parent pin-for-400 pin-fully-scrolled flex flex-wrap">
          <Spacer />
          <div className="items-container panel--w-half flex flex-col ">
            <Img src="measure" className="item" />
            <Img src="elevate" className="item" />
            <Img src="focus" className="item" />
            <Img src="realign" className="item" />
          </div>
        </div>
      </div>

      <Slide className="section-6">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="books2" />
        </div>
      </Slide>

      <Slide className="section-7">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="books1" />
        </div>
      </Slide>

      <Slide className="section-8">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: doubleoBlue, color: doubleoOrange }}
        >
          <div>
            <p>
              Elegant, not obvious. The corporate profile is all business on the
              outside and action on the inside. French folds conceal tactics and
              die-cuts create intrigue and reveal insights.
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-9">
        <div
          className="grid-items-container panel--w-full panel--h-screen flex flex-wrap grid-container"
          style={{ backgroundColor: doubleoBlue }}
        >
          <div className="item-container">
            <Img src="dob1" className="item" />
          </div>
          <div className="item-container">
            <Img src="dob2" className="item" />
          </div>
          <div className="item-container">
            <Img src="dob3" className="item" />
          </div>
          <div className="item-container">
            <Img src="dob4" className="item" />
          </div>
        </div>
      </Slide>

      <Slide className="section-10">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="books4" />
        </div>
      </Slide>

      <Slide className="section-11">
        <div
          className="panel--w-half panel--h-screen panel--image panel--image--centered panel--image--centered--large"
          style={{ backgroundColor: doubleoBlue }}
        >
          <Img src="pattern" className="pattern" />
        </div>
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="shirt" className="shirt" />
        </div>
      </Slide>

      <div className="section-12 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content "
          style={{ backgroundColor: doubleoOrange, color: doubleoBlue }}
        >
          <div className="panel--text">
            <p>
              First impressions count, right down to the (not so) corporate
              attire. Brand-patterned casual tees and polos create an
              appropriate look for Double-O.
            </p>
          </div>
        </div>
      </div>
      <Spacer />
      <Slide className="section-13">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="box" />
        </div>
      </Slide>
      <Slide className="section-14">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="box2" />
        </div>
      </Slide>

      <div className="section-15 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content "
          style={{ backgroundColor: doubleoBlue, color: doubleoOrange }}
        >
          <div>
            <p>
              Double-O’s work has a profound impact that creates lasting change.
              This DO Box workshop tool and expo give-away includes nothing more
              technical than a notepad and pen, because to get things done you
              first need to DO.
            </p>
          </div>
        </div>
      </div>
      <Spacer />
      <Slide className="section-16">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="card" className="h-full" />
        </div>
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="businesssuit" className="h-full" />
        </div>
      </Slide>

      {/* <Slide className="section-17">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="conference1" />
        </div>
      </Slide>
      <Slide className="section-18">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="conference2" />
        </div>
      </Slide> */}
      <Slide className="section-19">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: doubleoOrange, color: doubleoBlue }}
        >
          <div>
            <p>
              Double-O are now a leading business consultancy, effecting change
              within some of the largest corporates, organisations and Crown
              entities in New Zealand and overseas.
              <br />
              <br />
              <TransitionLink
                to="/projects/orbica/"
                color="#2b2b2b"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Next project
              </TransitionLink>
              <br />
              <TransitionLink
                to="/#projects"
                color="white"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block transform rotate-180"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Back to all projects
              </TransitionLink>
            </p>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default DoubleOProject
