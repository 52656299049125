import React from "react"

const Slide = ({ children, className, noWrapper }) => {
  return (
    <div className={!noWrapper ? `section-wrapper` : ``}>
      <section className={className + " pin-once-scrolled"}>{children}</section>
    </div>
  )
}

export default Slide
